import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/header.css';

const Header = () => {
    return (
        <header>
            <div className="header-container">
                <div className="header-logo">
                    <Link to="/">
                        <img src="logo.png" alt="logo" height={'60px'} />
                    </Link>
                </div>
                <div className="header-nav-container">
                    <nav>
                        <Link to="/tools">도구</Link>
                        <Link to="/1">1</Link>
                        <Link to="/2">2</Link>
                        <Link to="/3">3</Link>
                        <Link to="/4">4</Link>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;
